import C from "./StandardSidebar.module.css";

import type { Price } from "../../modules/pricing/resourcePricing.ts";
import { CurrencyMonthly } from "../l10n/Currency.tsx";

interface PriceBlockProps {
    price: Price;
    prefix?: string;
}

export function PriceBlock({ price, prefix }: PriceBlockProps) {
    return (
        <div className={C.StandardBlockInfo}>
            <div className="text-muted">Monthly cost</div>
            {/* biome-ignore lint/complexity/noUselessFragments: This adds a space */}
            {!!prefix && <>{prefix} </>}
            <CurrencyMonthly value={price.hourly} />
        </div>
    );
}
